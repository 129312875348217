import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: #2C3E50;
    --secondary-color: #3498DB;
    --accent-color: #E74C3C;
    --text-color: #2C3E50;
    --text-light: #6C7A89;
    --background-color: #FFFFFF;
    --gray-light: #F8F9FA;
    --gray-medium: #E9ECEF;
    --spacing-unit: 1rem;
    --container-width: 1200px;
    --header-height: 80px;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    color: var(--text-light);
  }

  a {
    color: var(--secondary-color);
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: var(--primary-color);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .container {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 var(--spacing-unit);
  }

  /* Skip to main content link */
  .skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: var(--secondary-color);
    color: white;
    padding: 8px;
    z-index: 100;
    
    &:focus {
      top: 0;
    }
  }

  /* Modern scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--gray-light);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 4px;
  }
`;

export default GlobalStyles; 
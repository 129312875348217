// SEO configuration and utilities
export const siteMetadata = {
  siteName: 'The ReCellar',
  siteUrl: 'https://yourdomain.com',
  title: 'The ReCellar - Vinted Reselling Guide 2024',
  description: 'Master Vinted reselling with our comprehensive guide. Learn automation, pricing strategies, and tools for success.',
  author: 'Your Name',
  social: {
    twitter: '@yourhandle',
    facebook: 'yourpage',
    instagram: 'yourprofile'
  },
  organization: {
    name: 'The ReCellar',
    logo: 'https://yourdomain.com/logo.png',
    sameAs: [
      'https://twitter.com/yourhandle',
      'https://facebook.com/yourpage',
      'https://instagram.com/yourprofile'
    ]
  }
};

export const generateWebsiteSchema = () => ({
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": siteMetadata.siteUrl,
  "name": siteMetadata.siteName,
  "description": siteMetadata.description,
  "publisher": {
    "@type": "Organization",
    "name": siteMetadata.organization.name,
    "logo": {
      "@type": "ImageObject",
      "url": siteMetadata.organization.logo
    },
    "sameAs": siteMetadata.organization.sameAs
  }
});

export const generateBreadcrumbSchema = (items) => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": items.map((item, index) => ({
    "@type": "ListItem",
    "position": index + 1,
    "name": item.name,
    "item": `${siteMetadata.siteUrl}${item.path}`
  }))
});

export const generateArticleSchema = (post, images = []) => ({
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": `${siteMetadata.siteUrl}/blog/${post.slug}`
  },
  "headline": post.title,
  "description": post.description,
  "image": images,
  "author": {
    "@type": "Person",
    "name": post.author
  },
  "publisher": {
    "@type": "Organization",
    "name": siteMetadata.organization.name,
    "logo": {
      "@type": "ImageObject",
      "url": siteMetadata.organization.logo
    }
  },
  "datePublished": post.datePublished,
  "dateModified": post.lastModified,
  "keywords": post.keywords.join(", ")
}); 
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 3rem 0;
  background: var(--gray-light);
  margin-top: 4rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
`;

const FooterSection = styled.div`
  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
`;

const BacklinkSection = styled.div`
  padding: 1rem 0;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  text-align: center;
  
  a {
    color: var(--secondary-color);
    text-decoration: none;
    transition: color 0.2s;
    
    &:hover {
      color: var(--primary-color);
    }
  }
`;

const ToolsSection = styled(FooterSection)`
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 1rem;
  }

  a {
    color: var(--secondary-color);
    text-decoration: none;
    transition: color 0.2s;
    
    &:hover {
      color: var(--primary-color);
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>About The ReCellar</h3>
          <p>Your ultimate guide to successful reselling on Vinted and beyond.</p>
        </FooterSection>
        
        <ToolsSection>
          <h3>Essential Tools</h3>
          <ul>
            <li>
              <a 
                href="https://resoled.it" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Vinted Bot by Resoled
              </a>
              <p>Automate your Vinted reselling business</p>
            </li>
            <li>
              <a 
                href="https://resoled.it/pricing" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Pricing Plans
              </a>
              <p>Find the right automation plan for your needs</p>
            </li>
          </ul>
        </ToolsSection>

        <FooterSection>
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/blog/how-to-start-reselling-on-vinted">Beginner's Guide</a></li>
            <li><a href="/blog/maximize-profits-vinted-reselling">Profit Tips</a></li>
          </ul>
        </FooterSection>
      </FooterContent>
      
      <BacklinkSection>
        <p>
          Supercharge your Vinted reselling with{' '}
          <a 
            href="https://resoled.it" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Visit Resoled.it for Vinted Bot (opens in new tab)"
          >
            Vinted Bot
          </a>
        </p>
      </BacklinkSection>
    </FooterContainer>
  );
}

export default Footer; 
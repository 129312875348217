import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderWrapper = styled.header`
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: var(--header-height);
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--spacing-unit);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--primary-color);
  text-decoration: none;
  
  span {
    color: var(--secondary-color);
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 2rem;
`;

const NavLink = styled(Link)`
  color: var(--text-color);
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background: var(--gray-light);
    color: var(--secondary-color);
  }
`;

function Header() {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo to="/">
          The <span>ReCellar</span>
        </Logo>
        <Nav>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/tools">Tools</NavLink>
          <NavLink to="/faq">FAQ</NavLink>
        </Nav>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default Header; 
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import LoadingSpinner from './components/LoadingSpinner';
import GlobalStyles from './styles/GlobalStyles';
import { Helmet } from 'react-helmet-async';
import { siteMetadata, generateWebsiteSchema } from './services/seo';
import { initGA, pageview } from './services/analytics';

const Home = lazy(() => import('./pages/Home'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Admin = lazy(() => import('./pages/Admin'));

// Analytics wrapper component
function AnalyticsWrapper({ children }) {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);

  return children;
}

function App() {
  return (
    <Router>
      <AnalyticsWrapper>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content={siteMetadata.description} />
          <link rel="canonical" href={siteMetadata.siteUrl} />
          
          {/* OpenGraph Meta Tags */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={siteMetadata.siteUrl} />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={`${siteMetadata.siteUrl}/og-image.jpg`} />
          
          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={siteMetadata.social.twitter} />
          <meta name="twitter:creator" content={siteMetadata.social.twitter} />
          <meta name="twitter:title" content={siteMetadata.title} />
          <meta name="twitter:description" content={siteMetadata.description} />
          <meta name="twitter:image" content={`${siteMetadata.siteUrl}/twitter-image.jpg`} />
          
          {/* Additional Meta Tags */}
          <meta name="application-name" content={siteMetadata.siteName} />
          <meta name="apple-mobile-web-app-title" content={siteMetadata.siteName} />
          <meta name="theme-color" content="#2C3E50" />
          
          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify(generateWebsiteSchema())}
          </script>
          
          {/* Preconnect to External Resources */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://api.unsplash.com" />
          
          {/* Sitemap */}
          <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />
        </Helmet>
        <GlobalStyles />
        <a href="#main-content" className="skip-link">Skip to main content</a>
        <Layout>
          <Suspense fallback={<LoadingSpinner />}>
            <main id="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/admin" element={<Admin />} />
              </Routes>
            </main>
          </Suspense>
        </Layout>
      </AnalyticsWrapper>
    </Router>
  );
}

export default App;

import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Main = styled.main`
  min-height: 100vh;
  padding: 2rem 0;
`;

function Layout({ children }) {
  return (
    <MainContainer>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </MainContainer>
  );
}

export default Layout; 